import React, { useState } from 'react'
import logo from '../images/logo.svg'
import { LogoWrapper } from '../utils/styles'
import { Link, navigate } from 'gatsby'
import tw, { styled } from 'twin.macro'
import { SmallSolidTealButton } from '../utils/styles'
import Twitter from '../images/Twitter.svg'
import YouTube from '../images/YouTube.svg'
import LinkedIn from '../images/LinkedIn.svg'
import ArrowRight from '../images/Arrow-right.svg'
import Finance from '../images/Finance.svg'
import Inventory from '../images/Inventory-1.svg'
import ShoppingCart from '../images/Shopping-Cart.svg'
import Speed from '../images/Speed.svg'
import Globe from '../images/Globe.svg'
import { LocaleContext } from './layout'
import LocalizedLink from './localized-link'

const HeaderWrapper = styled.div`
  box-shadow: 0px 2px 25px rgba(29, 33, 72, 0.0975863);
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  background: white;
  z-index: 100;
  @media (max-width: 768px) {
    position: fixed;
  }
`
const MobileDropDownWrapper = styled.div`
  position: fixed;
  top: 83px;
  width: 100%;
  background: white;
  z-index: 99;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  .m-menus {
    border-bottom: 1px solid #dee7ed;
  }
  .m-login {
    border-bottom: 1px solid #dee7ed;
  }
`
const SolutionDropDown = styled.div`
  grid-template-columns: 1fr 1fr;
  width: 500px;
  div {
    max-width: 213px;
  }
  ::after {
    background: linear-gradient(45deg, #1d5a80 50%, #1d5a80 51%, #1d5a80 100%);
    content: '';
    height: 10px;
    left: 70px;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 10px;
  }
`
const TranslatorDropDown = styled.div`
  left: 15%;
  ::after {
    background: linear-gradient(45deg, #1d5a80 50%, #1d5a80 51%, #1d5a80 100%);
    content: '';
    height: 10px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 10px;
  }
`
const ServiceDropDown = styled.div`
  left: -46%;
  ::after {
    background: linear-gradient(45deg, #1d5a80 50%, #1d5a80 51%, #1d5a80 100%);
    content: '';
    height: 10px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 10px;
  }
`
const IndustryDropDown = styled.div`
  left: -30%;
  ::after {
    background: linear-gradient(45deg, #1d5a80 50%, #1d5a80 51%, #1d5a80 100%);
    content: '';
    height: 10px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 10px;
  }
`
const HamburgerMenu = styled.div`
  margin-left: auto;

  #nav-icon3 {
    width: 18px;
    height: 16px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 1025;
  }
  #nav-icon3 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #007e9f;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  #nav-icon3 span:nth-of-type(1) {
    top: 0px;
  }

  #nav-icon3 span:nth-of-type(2),
  #nav-icon3 span:nth-of-type(3) {
    top: 8px;
  }

  #nav-icon3 span:nth-of-type(4) {
    top: 16px;
  }

  #nav-icon3.open span:nth-of-type(1) {
    top: 8px;
    width: 0%;
    left: 50%;
  }

  #nav-icon3.open span:nth-of-type(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon3.open span:nth-of-type(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon3.open span:nth-of-type(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
`
const SocialLink = styled.div`
  padding-top: 2rem;
  width: 24px;
  a {
    img {
      width: 24px;
      height: 24px;
    }
  }
`

const Header = (props) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [showSolution, setShowSolution] = useState(false)
  const [showTranslator, setShowTranslator] = useState(false)
  const [showIndustries, setshowIndustries] = useState(false)
  const [showServices, setShowServices] = useState(false)
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  let uidObject = new Object()
  if (typeof props.resourceList != 'undefined') {
    props.resourceList.forEach((r) => {
      uidObject[r.uid] =
        r.alternate_languages.length > 0 ? r.alternate_languages[0].uid : ''
    })
  }
  const handleDropdown = () => {
    setShowDropdown(!showDropdown)
  }
  const handleSolutionClick = () => {
    setShowSolution(!showSolution)
  }
  const handleTranslatorClick = () => {
    setShowTranslator(!showTranslator)
  }
  const handleIndustriesClick = () => {
    setshowIndustries(!showIndustries)
  }
  const handleServicesClick = () => {
    setShowServices(!showServices)
  }
  const handleLinkClick = (e, link) => {
    e.preventDefault()
    setShowDropdown(!showDropdown)
    const localizedLink = lang.locale === 'de-at' ? '/de' + link : link
    navigate(localizedLink)
  }
  const handleResourceLink = (link) => {
    if (Object.keys(uidObject).length === 0) {
      return link
    } else {
      const uid = link.substring(link.lastIndexOf('/') + 1, link.length)
      return link.replace(uid, uidObject[uid])
    }
  }
  //need to add locale value to local storage so website won't redirect automatically when visitor choose it
  const handleTranslatorLinkClick = (e, link, locale) => {
    e.preventDefault()
    setShowDropdown(!showDropdown)
    localStorage.setItem('locale', locale)
    if (link === '') {
      navigate('/' + link)
    } else {
      navigate(handleResourceLink(link))
    }
  }
  const handleDesktopTranslatorLinkClick = (e, link, locale) => {
    e.preventDefault()
    localStorage.setItem('locale', locale)
    if (link === '') {
      navigate('/' + link)
    } else {
      navigate(handleResourceLink(link))
    }
  }
  //solution dropdown content and link
  const SolutionDropDownContent = [
    {
      headline: i18n.dropdown.solution.predict.headline,
      desp: i18n.dropdown.solution.predict.desp,
      icon: ShoppingCart,
      to: 'predict-sales',
    },
    {
      headline: i18n.dropdown.solution.integrate.headline,
      desp: i18n.dropdown.solution.integrate.desp,
      icon: Speed,
      to: 'integrate-with-lightspeed',
    },
    {
      headline: i18n.dropdown.solution.optimize.headline,
      desp: i18n.dropdown.solution.optimize.desp,
      icon: Inventory,
      to: 'optimize-replenishment',
    },
    {
      headline: i18n.dropdown.solution.plan.headline,
      desp: i18n.dropdown.solution.plan.desp,
      icon: Finance,
      to: 'plan-finance',
    },
  ]
  return (
    <div style={{ display: 'initial' }}>
      <HeaderWrapper>
        <div tw="container flex py-5 md:py-7">
          <LocalizedLink to="/">
            <LogoWrapper>
              <img src={logo} />
            </LogoWrapper>
          </LocalizedLink>
          <nav
            aria-label="primary"
            tw="relative z-20 flex-col flex-grow hidden pb-4 md:pb-0 md:flex md:justify-start md:flex-row">
           
           
            <div tw="relative" className="group">
              <LocalizedLink to="/services">
                <button tw="flex flex-row items-center w-full px-4 py-4 mt-2 text-navy text-lg text-left md:w-auto md:mt-0 md:ml-4 focus:outline-none inline-flex">
                  {i18n.menu.services}
                 
                </button>
              </LocalizedLink>
            </div>
            <div tw="relative" className="group">
              <LocalizedLink to="/project">
                <button tw="flex flex-row items-center w-full px-4 py-4 mt-2 text-navy text-lg text-left md:w-auto md:mt-0 md:ml-4 focus:outline-none inline-flex">
                  {i18n.menu.industries}
                 
                </button>
              </LocalizedLink>
              
            </div>
            <div tw="relative">
              <LocalizedLink to="/blog">
                <button tw="flex flex-row items-center w-full px-4 py-4 mt-2 text-navy text-lg text-left md:w-auto md:inline md:mt-0  focus:outline-none">
                  {i18n.menu.resources}
                </button>
              </LocalizedLink>
            </div>
            
            <div tw="relative">
              <LocalizedLink to="/about-us">
                <button tw="flex flex-row items-center w-full px-4 py-4 mt-2 text-navy text-lg text-left md:w-auto md:inline md:mt-0  focus:outline-none">
                  {i18n.menu.about_us}
                </button>
              </LocalizedLink>
            </div>
              
          </nav>
         
          <LocalizedLink to="/request-meeting">
            <SmallSolidTealButton tw="hidden md:block">
              {i18n.buttons.meeting}
            </SmallSolidTealButton>
          </LocalizedLink>
          
          <HamburgerMenu
            onClick={handleDropdown}
            tw="md:hidden flex items-center">
            <div id="nav-icon3" className={showDropdown ? 'open' : ''}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </HamburgerMenu>
        </div>
      </HeaderWrapper>
      {/* mobile dropdown start from here */}
      {showDropdown ? (
        <MobileDropDownWrapper tw="h-screen">
          <div tw="mx-6 pb-4" className="m-menus">
           
            <div tw="relative">
              <Link to="/services"
                onClick={(e) => handleLinkClick(e, '/services')}>
                <button tw="flex justify-between items-center w-full py-4 mt-2 text-navy text-lg text-left md:w-auto md:inline md:mt-0 focus:outline-none">
                  {i18n.menu.services}
                  
                </button>
              </Link>
            </div>
            
            <div tw="relative">
              <Link  to="/project"
                onClick={(e) => handleLinkClick(e, '/project')}>
                <button tw="flex justify-between items-center w-full py-4 mt-2 text-navy text-lg text-left md:w-auto md:inline md:mt-0 focus:outline-none">
                  {i18n.menu.industries}
                 
                </button>
              </Link>
            </div>
            
            <div tw="relative">
              <Link
                to="/blog"
                onClick={(e) => handleLinkClick(e, '/blog')}>
                <button tw="flex flex-row items-center w-full py-3 mt-2 text-navy text-lg text-left md:w-auto md:inline md:mt-0  focus:outline-none">
                  {i18n.menu.resources}
                </button>
              </Link>
            </div>
            <div tw="relative">
              <Link
                to="/about-us"
                onClick={(e) => handleLinkClick(e, '/about-us')}>
                <button tw="flex flex-row items-center w-full py-3 mt-2 text-navy text-lg text-left md:w-auto md:inline md:mt-0  focus:outline-none">
                  {i18n.menu.about_us}
                </button>
              </Link>
            </div>
            <Link
              to="/request-meeting"
              onClick={(e) => handleLinkClick(e, '/request-meeting')}>
              <SmallSolidTealButton tw="my-4 w-full">
                {i18n.buttons.meeting}
              </SmallSolidTealButton>
            </Link>
          </div>
          

          
        </MobileDropDownWrapper>
      ) : (
        false
      )}
    </div>
  )
}
export default Header
